import React from "react"
import styled, { css } from "styled-components"
import SEO from "../components/seo"
import { Layout } from "../components/Layout/Layout"
import { Row, Col, Table } from "reactstrap"
import { graphql } from "gatsby"

import Markdown from "react-markdown"

import Zoom from "react-medium-image-zoom"
import { Slider } from "../components/Slider/Slider"
import Download from "../assets/download.svg"

import Map from "pigeon-maps"
import Marker from "pigeon-marker"

const Image = styled.div`
  width: calc(50% - 0.5rem);
  height: 20vh;
  margin: 0;
  margin-bottom: 1em;
  float: left;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &:not(:nth-child(odd)) {
    margin-left: 0.5rem;
    margin-right: 0;
  }
  &:not(:nth-child(even)) {
    margin-left: 0;
    margin-right: 0.5rem;
  }
`

const MapContainer = styled.div`
  width: 100%;
  margin-bottom: 2rem;
`

const H1 = styled.h1`
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 2em;
`

const H2 = styled.h2`
  padding: 0;
  margin-bottom: 2rem;
  font-weight: 100;
  font-size: 1.5rem;
  font-family: sans-serif;
`

const H3 = styled.h3`
  font-size: 1.25rem;
  margin-bottom: 1rem;
`

const DescriptionList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  margin-bottom: 1.5rem;
`

const DescriptionItem = styled.li`
  font-size: 0.85rem;
  margin-bottom: 0.5rem;
  padding-bottom: 0.3rem;
  border-bottom: 1px solid #ccc;
`

const TableWrapper = styled.div`
  max-height: 50vh;
  overflow-y: auto;
  border: 1px solid #ccc;
  margin-bottom: 2rem;
`

const FlatsTable = styled(Table)`
  thead,
  tbody {
    font-size: 0.75rem;
  }
  tr {
    vertical-align: middle;
    border-bottom: 1px solid #ccc;
  }

  th,
  td {
    text-align: center;
    ${props =>
      props.left &&
      css`
        text-align: left;
      `}
  }

  td {
    a {
      display: block;
      background-image: none;
      text-shadow: none;

      img {
        display: block;
        width: 25px;
        margin: 0;
      }
    }
  }
`

export default ({ data }) => {
  const {
    contentfulInvests: {
      investName,
      locationName,
      heroImage,
      description: { description },
      landscape,
      location,
      standards,
      installs,
      apartament,
      flats,
      parkings,
      gallery,
    },
  } = data

  return (
    <Layout>
      <SEO title={`${investName} - ${locationName}`} />
      <article>
        <Slider images={[{ ...heroImage }]} />
        <H1>{investName}</H1>
        <H2>{locationName}</H2>
        <Row>
          <Col>
            <MapContainer>
              <Map center={[location.lat, location.lon]} zoom={15} height={350}>
                <Marker anchor={[location.lat, location.lon]}></Marker>
              </Map>
            </MapContainer>
          </Col>
        </Row>
        <Row>
          <Col sm="12" md="6" lg="8">
            <Row>
              <Col>
                <Markdown source={description} escapeHtml={false} />
              </Col>
            </Row>
            <Row>
              <Col>
                <H3>Mieszkania</H3>
                <TableWrapper>
                  <FlatsTable responsive borderless hover>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Lokalizacja</th>
                        <th>Piętro</th>
                        <th>Liczba pokoi</th>
                        <th>Powierzchnia</th>
                        <th>
                          Cena m<sup>2</sup>
                        </th>
                        <th>Rzut</th>
                      </tr>
                    </thead>
                    <tbody>
                      {!!flats &&
                        flats.map((flat, index) => (
                          <tr key={index}>
                            <th scope="row">{flat.flatNumber}</th>
                            <td>
                              {flat.flatLocation
                                ? flat.flatLocation.locationName
                                : null}
                            </td>
                            <td>{flat.floor}</td>
                            <td>{flat.rooms}</td>
                            <td>{flat.surface}</td>
                            <td>{flat.price}</td>
                            <td>
                              {flat.screen && (
                                <a
                                  href={flat.screen.file.url}
                                  title={`Pobierz rzut mieszkania nr ${flat.flatNumber}`}
                                >
                                  <img
                                    src={Download}
                                    alt=""
                                    aria-hidden="true"
                                  />
                                </a>
                              )}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </FlatsTable>
                </TableWrapper>
              </Col>
            </Row>
            <Row>
              <Col>
                <H3>Miejsca postojowe</H3>
                <TableWrapper>
                  <FlatsTable left={true} responsive borderless hover>
                    <tbody>
                      {!!parkings &&
                        parkings.map((parking, index) => (
                          <tr key={index}>
                            <td>{parking}</td>
                          </tr>
                        ))}
                    </tbody>
                  </FlatsTable>
                </TableWrapper>
              </Col>
            </Row>
            <Row>
              <Col>
                {gallery &&
                  gallery.map(photo => (
                    <Image>
                      <Zoom
                        image={{
                          src: photo.file.url,
                        }}
                        zoomImage={{
                          src: photo.file.url,
                        }}
                      ></Zoom>
                    </Image>
                  ))}
              </Col>
            </Row>
          </Col>
          <Col sm="12" md="6" lg="4">
            {landscape && (
              <Row>
                <Col>
                  <H3>W pobliżu</H3>
                  <DescriptionList>
                    {landscape.map((item, index) => (
                      <DescriptionItem key={index}>{item}</DescriptionItem>
                    ))}
                  </DescriptionList>
                </Col>
              </Row>
            )}
            {standards && (
              <Row>
                <Col>
                  <H3>Standard</H3>
                  <DescriptionList>
                    {standards.map((item, index) => (
                      <DescriptionItem key={index}>{item}</DescriptionItem>
                    ))}
                  </DescriptionList>
                </Col>
              </Row>
            )}
            {apartament && (
              <Row>
                <Col>
                  <H3>Lokal mieszkalny</H3>
                  <DescriptionList>
                    {apartament.map((item, index) => (
                      <DescriptionItem key={index}>{item}</DescriptionItem>
                    ))}
                  </DescriptionList>
                </Col>
              </Row>
            )}
            {installs && (
              <Row>
                <Col>
                  <H3>Instalacje</H3>
                  <DescriptionList>
                    {installs.map((item, index) => (
                      <DescriptionItem key={index}>{item}</DescriptionItem>
                    ))}
                  </DescriptionList>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </article>
    </Layout>
  )
}

export const query = graphql`
  query($investName: String!) {
    contentfulInvests(investName: { eq: $investName }) {
      investName
      landscape
      locationName
      location {
        lat
        lon
      }
      flats {
        finished
        id
        floor
        flatNumber
        flatLocation {
          investName
          locationName
        }
        price
        reservation
        rooms
        screen {
          file {
            url
          }
        }
        sold
        surface
      }
      parkings
      standards
      installs
      gallery {
        file {
          url
        }
      }
      heroImage {
        file {
          url
        }
      }
      description {
        description
      }
      apartament
    }
  }
`
